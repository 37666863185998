main {
    min-height: 80vh;
}

.rating span {
    margin: 0.1rem;
}

.rating svg {
    /*color: #f8e825;*/
    color: #FD7014;
}

.pagination-item-active-custom .page-link {
    background-color: #FD7014 !important;
    border-color: white !important;
    color: #ffffff !important;
    border: 1px solid #FD7014 !important;
}

.pagination-item {
    /*border: 1px solid #000000 !important; !* Border around each Pagination.Item *!*/
    border-radius: 4px; /* Optional: Add border radius for rounded corners */
    margin: 2px; /* Optional: Add margin for spacing between items */
    padding: 5px; /* Optional: Add padding for spacing within items */
}

.pagination-container {
    /*border: 1px solid orange; !* Border around the Pagination component *!*/
    border-radius: 4px; /* Optional: Add border radius for rounded corners */
    padding: 30px; /* Optional: Add padding for spacing */
}


.pagination-arrows {
    color: #3E6D9C;
}

.rating-text {
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 0.5rem;
}

.product-title {
    height: 2.5em; /* Set a fixed height */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis for long text */
    white-space: nowrap; /* Prevent wrapping */
    text-align: center; /* Center align text */
    text-decoration: none; /* Remove underline */
}

.product-price {
    text-align: center; /* Center align text */
    font-weight: bold; /* Make the price bold */
}

product-price {
    font-size: 1rem;
    margin: 0.5rem 0;
}

.original-price {
    text-decoration: line-through;
    color: grey;
}

.product-price {
    font-size: 1rem;
    margin: 0.5rem 0;
}

.product-price {
    font-size: 1rem;
    margin: 0.5rem 0;
}

.original-price {
    text-decoration: line-through;
    color: grey;
}

.discounted-price {
    font-weight: bold;
    color: green;
}

.product-promo {
    font-weight: bold; /* Make the promo text bold */
    color: #ff0000; /* Set the text color to red */
    background-color: #ffffff; /* Set background color to white */
    padding: 8px; /* Add padding around the text */
    border: 2px solid #ff0000; /* Red border around the text box */
    border-radius: 5px; /* Rounded corners for the border */
    margin: 10px 0; /* Add vertical margin for spacing */
    display: block; /* Make it block to take full width */
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2); /* Optional: Adds shadow for 3D effect */
    text-align: center; /* Center align text */
}

.product-rating {
    text-align: center; /* Center align text */
}
@media (max-width: 576px) {
    .product-price,
    .product-rating {
        display: none;
    }
    .product-card img {
        max-width: 100%;
        height: auto;
    }
}



table td,
table th {
    text-align: center;
}

.review {
    margin-top: 30px;
}

.review h2 {
    font-size: 24px;
    background: #f4f4f4;
    padding: 10px;
    border: 1px solid #ddd;
}

.review button {
    margin-top: 10px;
}

.carousel-caption {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}


.color-nav{
    color: white;
}

.formLabel {
    font-size: 18px;
    margin-bottom: 15px;
}

/* Hover effect for NavDropdown */
.nav-dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
}
/*Login name colour*/
#nav-dropdown{
    color: #000000;

}

#nav-user-dropdown{
    color: #000000;
}

#nav-dropdownTwo{
    color: white;
}

#nav-dropdownThree{
    color: white;
    font-size: 0.8rem;
    align-items: center;

}

#nav-user-dropdown {
    text-align: left; /* Adjust this as needed */
}

#nav-user-dropdown .dropdown-menu {
    position: absolute;
    left: 0; /* Aligns the dropdown to the left of the NavDropdown */
}




/* Custom CSS for multi-column NavDropdown */
.multi-column-dropdown .container {
    width: auto;
    color: white;
}

.multi-column-dropdown .row {
    display: flex;
}

.multi-column-dropdown .col {
    flex: 1;
    padding: 10px;
    border-right: 1px solid #ccc;
}

.multi-column-dropdown .col:last-child {
    border-right: none;
}

.multi-column-dropdown .dropdown-header {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.icon-model {
    width: 100%;
}

.icon-img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 600px) {
    .icon-img {
        width: 90%;
    }
}

/*!* Adjustments for smaller screens *!*/
/*@media (max-width: 768px) {*/
/*    .thumbnail {*/
/*        width: 75px; !* Smaller width on small screens *!*/
/*        margin: 2px; !* Smaller margin to fit more thumbnails *!*/
/*    }*/
/*}*/

/*!* Additional adjustments for even smaller screens *!*/
/*@media (max-width: 480px) {*/
/*    .thumbnail {*/
/*        width: 50px; !* Even smaller thumbnails for very small screens *!*/
/*        margin: 1px; !* Reduced margin *!*/
/*    }*/
/*}*/

/*.fixed-header {*/
/*  position: fixed;*/
/*  top: 0;*/
/*  width: 100%;*/
/*  z-index: 1000; !* ensure it's on top of other content *!*/
/*}*/


.header-wrapper {
    width: 100%;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Make sure the header is always on top */
}
.main-content {
    padding-top: 10px; /* Adjust based on your header's height */
}

.invisible-toggle {
    opacity: 0;
    background-color: transparent !important;
    border: none !important;
}

.parent-container {
    display: flex;
    justify-content: center; /* centers horizontally */
    align-items: center;     /* centers vertically */
    /*height: 100vh;           !* makes the container take up the full viewport height *!*/
}

/* Default width for larger screens
 change the colourof the search box on the home page */

.search-input {
    width: 400px;
    outline: none !important;          /* Remove the default outline */
    /*box-shadow: 0 0 0 0.2rem #FD7014;  !* Add a shadow to simulate an outline *!*/
    border-color: black !important;  /* Adjust the border color to match */
    color: black;

}

/* Media query for smaller screens (e.g., less than 768px, which is a typical breakpoint for tablets and mobile devices) */
@media (max-width: 767px) {
    .search-input {
        width: 400px;
    }
}
/*change the colour of the text in the search bar*/
.search-input::placeholder {
    color: #000000; /* or any color you prefer */
    opacity: 0.5; /* Firefox */
}

/* In your CSS */
.avatar {
    border-radius: 50%;
}

.desktop-nav .dropdown {

    display: none;
}
/*the username in the navbar*/
.nav-dropdown .dropdown-toggle::after {
    display: none !important;


}
.nav-dropdown .dropdown-toggle:hover {
    background-color: #e5e5e5;
    color: white;
}
/*Scroll icons*/
.horizontal-scroll {
    display: flex !important;
    overflow: hidden !important;
    width: 110% !important;
    border: 5px solid #ffffff !important;
    border-radius: 10px !important;
    flex-wrap: nowrap !important;
    overflow-x: auto !important; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch !important; /* Makes scrolling smoother on iOS */
    margin-left: -40px !important; /* Add padding to the right */
}



/*Text icon description*/
.description-style {
    font-family: "Gill Sans", sans-serif;;
    text-align: center;
    font-size: 1.5rem;
}


/* CustomStyles.css */
.dropdown-menu {
    min-width: auto; /* Adjust based on your content and design */
}

.dropdown-item {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
}

/* CustomStyles.css */
.dropdown-col {
    flex: 0 0 20%; /* Adjust this to control the width of each column */
    max-width: 20%; /* This ensures that the column does not exceed 20% of the parent width */
    padding: 5px; /* Adjust padding as needed for spacing */
    display: flex;
    justify-content: center; /* Center content within the column */
}

.cancel-order-btn {
    color: #dc3545; /* Bootstrap's $danger color for red text */
    background-color: white;
    border-color: #dc3545; /* Red border */
    width: 100%; /* Make the button expand to the full width of its parent container */
}
.cancel-order-btn:hover {
    color: white; /* White text on hover */
    background-color: #dc3545; /* Red background on hover */
    border-color: #dc3545; /* Red border, same as hover background for consistency */
}

.empty-space {
    /* Adjust the width as needed to simulate the space you want */
    width: 36px; /* Example width, adjust based on your font and layout */
    display: inline-block; /* or block, depending on your layout */
}

.btn-mobile-responsive {
    padding: 10px 20px; /* Default padding */
    font-size: 16px; /* Default font size */
}

@media screen and (max-width: 768px) {
    .btn-mobile-responsive {
        padding: 5px 10px; /* Smaller padding */
        font-size: 14px; /* Smaller font size */
    }
}

.header-wrapper {
    position: relative;
}

.header-wrapper::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px; /* Adjust the thickness of the line as needed */
    background-color: #FD7014; /* Set the color of the line */
}

/* Orange strip at the top of the footer */
footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px; /* Adjust the thickness of the line as needed */
    background-color: #3E6D9C; /* Set the color of the line */
}

/* Orange strip at the bottom of the footer */
footer::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px; /* Adjust the thickness of the line as needed */
    background-color: #FD7014; /* Set the color of the line */
}

.custom-margin {
    margin-top: 30px;
    margin-bottom: 0px; /* Adjust the value as needed */
}


.icon-link .svg-inline--fa {
    width: 1.5em !important; /* Adjust the size as needed */
    height: 1.5em !important; /* Adjust the size as needed */
    opacity: 1.0; /* Adjust the opacity to make the icons less bold */
}

.icon-container {
    display: flex;
    justify-content: space-between; /* Adjust spacing as needed */
    align-items: center; /* Adjust alignment as needed */
}

.icon-link {
    display: block;
    padding: 30px; /* Increase padding */
    margin: 0 10px; /* Add margin */
}

.icon-link-container {
    margin: 0 40px; /* Add margin to all icon links */
    text-align: center; /* Center align all icon links */
}

/*From here*/

/* Default state colors */
.icon-link-container .icon-wrapper .icon {
    color: black; /* Primary color for the icon */
    /*opacity: 0.8;*/
}

.icon-link-container .icon-wrapper .icon .fa-secondary {
    color: darkgrey; /* Secondary color for the icon */
    opacity: 1;
}

.icon-link-container .icon-wrapper .icon-text {
    color: black; /* Color for the text */

}

/* Hover state colors - switch primary and secondary colors for both icon and text */
.icon-link-container .icon-wrapper:hover .icon {
    color: #3E6D9C; /* New primary color for the icon on hover */
    /*opacity: 0.8;*/
}

.icon-link-container .icon-wrapper:hover .icon .fa-secondary {
    color: #FD7014; /* New secondary color for the icon on hover */
    opacity: 1;
}

.icon-link-container .icon-wrapper:hover .icon-text {
    color: #FD7014; /* New color for the text on hover */
}

.headerNavSpacing {
    margin-top: 15px; /* Adjust the value as needed */
    margin-bottom: 15px; /* Adjust the value as needed */
    margin-left: -50px; /* Default margin-left for full screen */
    margin-right: -50px; /* Default margin-right for full screen */
}

@media (max-width: 900px) {
    .headerNavSpacing {
        margin-left: -60px; /* Margin-left for mobile view */
        margin-right: -40px;
    }
}

/*NavBar size or sizing of icons*/
/* Default size for larger screens */
.icon-wrapper .icon {
    font-size: 1.5em; /* Equivalent to 1x */
}

/* Size for mobile screens */
@media (max-width: 1000px) {
    .icon-wrapper .icon {
        font-size: 2.5em; /* Equivalent to 3x */

    }
}


.navbar-nav::-webkit-scrollbar {
    display: none;  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.icon-wrapper {
    display: inline-flex; /* Use inline-flex to prevent wrapping */
    flex-direction: column;
    align-items: center; /* Center align icons and text vertically */
}
/*NavBarIcons*/
.icon {
    margin-bottom: 5px; /* Add spacing between icon and text */
    color: black;
    font-weight: 900; /* Trying to enhance boldness, effectiveness varies */


}
/*NavBar Text*/
.icon-text {
    font-size: 15px; /* Adjust the font size */
    font-weight: 300; /* Adjust the font weight */
    color: black;
    /*font-weight: bold; !* Set the font text to bold *!*/
    white-space: nowrap; /* Prevent text from wrapping */
}

.favorite-button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    outline: none; /* Remove outline when button is focused */
}
.button-container {
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    margin-top: 1rem; /* Adjust margin as needed */
}

/*//image size in the carousel*/
/*.my-carousel .carousel-item img {*/
/*    display: block;*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*    width: 100%; !* Adjust this to control image sizing *!*/
/*    max-width: 100%; !* Ensure images are responsive *!*/
/*}*/
/* Image size in the carousel */
/* Image size in the carousel for normal screens */

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%; /* Adjust width to auto */
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.carousel-control-prev {
    left: 10px !important; /* Move closer to the edge of the screen */
    padding: 0 !important;  /* Remove padding */
    margin: 0 !important;   /* Remove margin */
}

.carousel-control-next {
    right: 10px !important; /* Move closer to the edge of the screen */
    padding: 0 !important;  /* Remove padding */
    margin: 0 !important;   /* Remove margin */
}


/*BurgerMenu --------------------------------------------------------------*/
/* Custom styles for the navbar collapse */
.navbar-collapse {
    background-color: #fff; /* Change as per your theme */
    padding: 1rem 0; /* Add some padding */
}

/*Style the nav-links within the collapse for better spacing and alignment */
/*.navbar-collapse .nav-link {*/
/*    padding: 0.5rem 1rem; !* Adjust padding for better spacing *!*/
/*    color: black; !* Adjust link color *!*/
/*}*/

/* Improve the toggle button appearance */
.navbar-toggler {
    /*border-color: #007bff; !* Adjust the toggle button border color *!*/
}

/* Optional: Style the toggle icon color */
/*.navbar-toggler-icon {*/
/*    background-image: url("data:image/svg+xml, ..."); !* Change the icon color *!*/
/*}*/

.navbar .search-box {
    width: 100%; /* Full width */
    padding: 8px;
    margin-bottom: 15px; /* Space from the next element */
    border: 1px solid #ccc;
    border-radius: 4px; /* Optional: for rounded corners */
}

.navbar .nav-icon {
    font-size: 20px; /* Adjust the size as needed */
    margin-right: 15px; /* Space between icons */
}

.navbar .nav-link {
    opacity: 1.0;
    display: flex;
    align-items: start;
    justify-content: start;
    padding: 10px 10px 10px 10px; /* Top, Right, Bottom, Left */
    color: black;
}
/*BurgerMenu --------------------------------------------------------------*/

/*contacts*/
.contact {
    padding-left: 40px;
    display: flex;
    justify-content: start;
    align-items: start;
}

/*underlines in the contact page*/
.my-divider {
    border: none;
    border-top: 4px dotted #000;
    width: 20%;
    margin: auto;
    opacity: 0.1;
    align-items: start;
}

/*Footer Styling*/
.footer-list-item {
    padding-bottom: 0.5rem; /* Adjust the spacing as needed */
}

.footer-social-icon {
    font-size: 36px; /* Example size */
    color: #3E6D9C;
    margin-right: 0.75rem; /* Adjust the spacing between icons */
}
/*Header Icons*/
.signin-link svg {
    margin-right: 0.5rem;  /*Adjusts spacing between the icon and text */
    color: black;
    text-decoration: none;
}

.signin-icon {
    vertical-align: middle;
    margin-top: 3px;  /*Adjust this value as needed to fine-tune the alignment*/
    color: black;
    text-decoration: none;
}
.basket-link svg {
    margin-right: 0.25rem;  /*Adjusts spacing between the icon and text */
}

.basket-icon {
    vertical-align: middle;
    /*margin-top: 0px;  !*Adjust this value as needed to fine-tune the alignment*!*/
}

.centered-select > select {
    width: 100%;
    max-width: 200px; /* Adjust this value based on your design needs */
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: black;
    cursor: pointer;
    font-family: Arial, sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

/*change the colour of the table in the products screen*/
/*.custom-striped-table tbody tr:nth-of-type(even) {*/
/*    background-color: rgba(253, 112, 20, 0.1); !* Orange color with 50% opacity for odd rows *!*/
/*}*/
/*.custom-striped-table tbody tr:nth-of-type(odd) {*/
/*    background-color: rgba(62, 109, 156, 0.0); !* Blue color with 50% opacity for even rows *!*/
/*}*/


.orange-outline {
    border: 2px solid #FD7014; /* Solid orange border */
    color: #FD7014; /* Orange text to match the border */
    background-color: transparent; /* Transparent background */
}
.orange-outline:hover {
    background-color: #FD7014; /* Orange background on hover */
    color: white; /* White text on hover */
}

.blue-outline {
    background-color: #3E6D9C; /* Blue background on hover */
    colorCborder: 2px solid #3E6D9C; /* Solid blue border */
    color: #3E6D9C; /* Blue text to match the border */
    background-color: transparent; /* Transparent background */
}
::-webkit-scrollbar {
    display: none;
}

/* Ensuring the container is set up properly */
.horizontal-scroll-nav {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    height: 100px;

}


.navbar-nav {
    display: flex;
    flex-wrap: nowrap;
}

.nav-arrow {
    flex: 0 0 auto;
    cursor: pointer;
    border: none;
    background: none;
}
.about-heading {
    margin-top: 30px; /* Adjust the value as needed */
}

.why-choose-section {
    margin-top: 30px; /* Adjust the value as needed */
}
.section-heading {
    margin-top: 30px; /* Adjust the value as needed */
}

/* Centers the image within its card */
.image-card {
    width: 100%; /* Ensures the card spans the full container width */
    display: flex;
    justify-content: center; /* Centers the image horizontally */
    padding: 20px; /* Provides padding around the image */
}

.about-image {
    width: 90%; /* Adjust this percentage to your preference */
    height: auto; /* Maintains the image's aspect ratio */
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

/* Style for the top choices section */
.landing-section {
    padding: 2rem 0 0.5rem;
}

.landing-section h1 {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1.5rem;
    color: black;
}

.product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.product-card {
    flex: 1 1 calc(33% - 2rem); /* Increased size to approximately one-third of the container width */
    margin: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.banner {
    width: 100vw; /* Full viewport width */
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: transparent; /* Make the background transparent */
    border: 1px solid #3E6D9C; /* Outline color */
    text-align: center;
    padding-left: 5rem; /* Add this line to push the text further to the right */
    margin-bottom: 2rem; /* Adjust this value to your desired bottom margin */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.banner .container {
    padding: .4rem 0; /* Add padding to the container */
}

.banner h1 {
    margin: 0; /* Remove margin from h1 */
    font-size: 1.0rem;
    /*color: #3E6D9C !important; !* Ensure the text matches the outline color *!*/
    opacity: 1; /* Ensure full opacity */
    font-family: "Sans-serif", Helvetica;
    text-align: center;
    padding-right: 5rem; /* Adjust this value to move text to the left */
}

.banner2 {
    width: 100vw; /* Full viewport width */
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: transparent; /* Make the background transparent */
    border: 1px solid #FD7014; /* Outline color */
    text-align: center;
    padding-left: 5rem; /* Add this line to push the text further to the right */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.banner2 .container {
    padding: 0.4rem 0; /* Add padding to the container */
}

.banner2 h1 {
    margin: 0; /* Remove margin from h1 */
    font-size: 1.0rem;
    /*color: #FD7014 !important; !* Ensure the text matches the outline color *!*/
    opacity: 1; /* Ensure full opacity */
    font-family: "Sans-serif", Helvetica;
    text-align: center;
    padding-right: 5rem; /* Adjust this value to move text to the left */
}



.product-cardCarousel {
    width: 100vw; /* Full viewport width */
    position: relative;
    margin-left: -60vw;
    margin-right: -60vw;
    text-align: center;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.product-link {
    display: block;
    width: 100vw;
    text-decoration: none; /* Optional: to remove underline from the link */
    color: inherit; /* Optional: to inherit the text color */
}

/* Import Lora font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&display=swap');

.sexy-text {
    font-family: 'Lora', serif;
    font-size: 1.0rem; /* Adjust as needed */
    color: #FFFF; /* A stylish red color */
    background: linear-gradient(to right, #000, #000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-top: 2rem;
    letter-spacing: 1px;
    line-height: 1.5;
    transition: transform 0.5s ease-in-out;
}

.sexy-text:hover {
    transform: scale(1.05);
}

/* Optional: Add a subtle animation */
/*@keyframes fadeIn {*/
/*    from {*/
/*        opacity: 0;*/
/*        transform: translateY(-10px);*/
/*    }*/
/*    to {*/
/*        opacity: 1;*/
/*        transform: translateY(0);*/
/*    }*/
/*}*/

.sexy-text {
    animation: fadeIn 1s ease-in-out;
}

@import url('https://fonts.googleapis.com/css2?family=Lora:wght@700&display=swap');

.sexy-text2 {
    font-family: 'Lora', serif;
    font-size: 1.0rem; /* Adjust as needed */
    color: #FFF; /* A stylish white color */
    background: linear-gradient(to right, #000, #000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);*/
    margin-top: 2rem;
    letter-spacing: 1px;
    line-height: 1.5;
    transition: transform 0.3s ease-in-out;
}

.sexy-text2:hover {
    transform: scale(1.05);
}

/* Optional: Add a subtle animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sexy-text2 {
    animation: fadeIn 1s ease-in-out;
}

.custom-carousel-indicators .carousel-indicators {
    bottom: -30px !important; /* Adjust this value to move the indicators further down */
}

/* CarouselCustom.css */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    padding: 10px; /* Optional: change the size of the arrows */
}

.carousel-control-prev,
.carousel-control-next {
    width: 0%; /* Optional: change the position of the arrows */
}

/* Custom styles for specific carousel controls */
.carousel-control-prev-2,
.carousel-control-next-2 {
    width: 0%; /* Optional: adjust the width as needed */
}

/* Make sure to adjust the clickable area for the arrows */
.carousel-control-prev-2:hover,
.carousel-control-next-2:hover {
    width: 0%; /* Adjust hover area if necessary */
}


/* CustomCarousel.css */

/* Default styles for carousel images */
/* CustomCarousel.css */

/* CustomCarousel.css */

/* Default styles for carousel images */
.carousel-item .product-card img {
    width: 100%;
    height: auto;
}

/* Styles for medium and small screens */
@media (max-width: 992px) {
    .first-carousel .carousel-item .product-card {
        transform: scale(1.2); /* Adjust this value to scale the product card */
        margin: 10px 0; /* Optional: Adjust margin as needed */
    }
}
/*to make the thumbnail section scrollable productItemCarousel*/
.thumbnail-container {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    white-space: nowrap;
    scrollbar-width: thin; /* For Firefox */
}

.thumbnail-container img {
    max-height: 100px;
    object-fit: cover;
    cursor: pointer;
    border: 1px solid #000;
    flex: 0 0 auto;
}

.thumbnail-scroll-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 24px;
}

.thumbnail-wrapper {
    overflow: hidden;
    width: calc(100px * 5 + 40px); /* 5 thumbnails + 40px for gaps */
}

/* Adjust the sizes on smaller screens */
@media (max-width: 1000px) {
    .thumbnail-wrapper {
        width: calc(140px * 5 + 40px); /* Increase thumbnail wrapper width */
    }

    .thumbnail-container img {
        max-height: 140px; /* Increase thumbnail height */
    }

    /*.carousel-item img {*/
    /*    max-height: 1000px; !* Increase main image height *!*/
    /*    width: auto; !* Maintain aspect ratio *!*/
    /*    object-fit: contain; !* Ensure the entire image is visible *!*/
    /*}*/
}

/*!* Main image larger size for larger screens *!*/
/*@media (min-width: 1001px) {*/
/*    .carousel-item img {*/
/*        max-height: 1000px; !* Increase main image height *!*/
/*        width: auto; !* Maintain aspect ratio *!*/
/*        object-fit: contain; !* Ensure the entire image is visible *!*/
/*    }*/
/*}*/

@media (min-width: 1001px) {
    .my-carousel .carousel-item img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 75%; /* Adjust this to control image sizing */
        max-width: 75%; /* Ensure images are responsive */
        height: auto; /* Maintain aspect ratio */
    }
}

/* Image size in the carousel for small screens */
@media (max-width: 1000px) {
    .my-carousel .carousel-item img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%; /* Full width for small screens */
        max-width: 90%; /* Ensure images are responsive */
        height: auto; /* Maintain aspect ratio */
    }
}

.trustbox-section {
    text-align: left; /* Ensures the widget aligns to the left */
}

.trustbox-section .trustpilot-widget {
    margin-left: -70px; /* Remove any default margin */
    max-width: 300px; /* Adjust the width of the widget as needed */
    margin-bottom: 20px; /* Add margin to the bottom */
;
}













/*Product Screen - might remove this to have two boxes*/
/* Hide the large description on smaller screens */
/*.product-description-large {*/
/*    display: none;*/
/*}*/

/*!* Show the large description on screens wider than 768px *!*/
/*@media (min-width: 768px) {*/
/*    .product-description-large {*/
/*        display: block;*/
/*    }*/
/*    .product-description-small {*/
/*        display: none;*/
/*    }*/
/*}*/





